import React, { useEffect, useRef } from 'react';

import { Styled } from '../theme';

interface IVideoProps {
	zoom: number
	contrast: number
	brightness: number
}

interface IProps extends IVideoProps {
	video?: any | boolean
}

export const Video = Styled<"video", IVideoProps>("video")(
	({ theme, zoom, contrast, brightness }) => ({
		transform: `scale(${zoom})`,
		filter: `contrast(${contrast}) brightness(${brightness})`
	})
)

export const Camera: React.FC<IProps> = ({ video, zoom, contrast, brightness }) => {
	const videoRef = useRef<HTMLVideoElement>(null)
	useEffect(() => {
		if (navigator.mediaDevices) {
			navigator.mediaDevices
				.getUserMedia({ video })
				.then((_mediaStream: any) => {
					if (videoRef.current) {
						videoRef.current.srcObject = _mediaStream
						videoRef.current.play()
					}
				})
				.catch(error => error)
		}
	}, [video])

	return (
		<div style={{ width: 300, height: 300, overflow: "hidden" }}>
			<Video
				ref={videoRef}
				width={300}
				height={300}
				zoom={zoom}
				contrast={contrast}
				brightness={brightness}
			/>
		</div>
	)
}
