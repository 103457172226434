import React, { useCallback, useReducer } from 'react';
import { FiZoomIn } from 'react-icons/fi';
import { IoMdContrast } from 'react-icons/io';
import { MdAdd, MdBrightnessMedium, MdRemove } from 'react-icons/md';

import { TOGGLE_BRIGHTNESS, TOGGLE_CONTRAST, TOGGLE_ZOOM } from '../actions/controls.actions';
import { ControlsInitialState, ControlsReducer } from '../reducers/controls.reducer';
import { Styled } from '../theme';

interface IProps {
	zoom: number
	setZoom: React.Dispatch<React.SetStateAction<number>>
	contrast: number
	setContrast: React.Dispatch<React.SetStateAction<number>>
	brightness: number
	setBrightness: React.Dispatch<React.SetStateAction<number>>
}

const Container = Styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: theme.width[12],
	height: theme.height.full
}))

const Stripe = Styled("div")(({ theme }) => ({
	flex: 1,
	width: theme.borderWidths.xs,
	background: theme.colors.white
}))

interface IButtonProps {
	active?: boolean
}

const Button = Styled<"button", IButtonProps>("button")(({ theme, active }) => ({
	background: theme.colors.transparent,
	outline: "none",
	fontSize: theme.fontSizes["3xl"],
	border: "0",
	width: theme.width[10],
	color: active ? theme.colors.white : theme.colors.coolGreyDark,
	borderRadius: theme.borderRadius.full
}))

const ControlButton = Styled(Button)(({ theme, active }) => ({
	color: active ? theme.colors.red : theme.colors.coolGreyDark,
	textShadow: active ? `0px 0px 5px ${theme.colors.redLight}` : "none"
}))

export const Controls: React.FC<IProps> = ({
	zoom,
	setZoom,
	contrast,
	setContrast,
	brightness,
	setBrightness
}) => {
	const [modes, dispatch] = useReducer(ControlsReducer, ControlsInitialState)
	const onIncrease: React.MouseEventHandler<HTMLButtonElement> = useCallback(
		e => {
			if (modes.zoom) {
				setZoom(zoom + 0.5)
			}
			if (modes.contrast) {
				setContrast(contrast + 0.5)
			}
			if (modes.brightness) {
				setBrightness(brightness + 0.5)
			}
		},
		[zoom, setZoom, contrast, setContrast, brightness, setBrightness, modes]
	)
	const onDecrease: React.MouseEventHandler<HTMLButtonElement> = useCallback(
		e => {
			if (modes.zoom) {
				setZoom(zoom - 0.5)
			}
			if (modes.contrast) {
				setContrast(contrast - 0.5)
			}
			if (modes.brightness) {
				setBrightness(brightness + 0.5)
			}
		},
		[zoom, setZoom, contrast, setContrast, brightness, setBrightness, modes]
	)

	return (
		<Container>
			<Button active={modes.zoom || modes.contrast || modes.brightness} onClick={onIncrease}>
				<MdAdd />
			</Button>
			<Stripe />
			<ControlButton active={modes.zoom} onClick={() => dispatch({ type: TOGGLE_ZOOM })}>
				<FiZoomIn />
			</ControlButton>
			<Stripe />
			<ControlButton active={modes.contrast} onClick={() => dispatch({ type: TOGGLE_CONTRAST })}>
				<IoMdContrast />
			</ControlButton>
			<Stripe />
			<ControlButton
				active={modes.brightness}
				onClick={() => dispatch({ type: TOGGLE_BRIGHTNESS })}
			>
				<MdBrightnessMedium />
			</ControlButton>
			<Stripe />
			<Button active={modes.zoom || modes.contrast || modes.brightness} onClick={onDecrease}>
				<MdRemove />
			</Button>
		</Container>
	)
}
