import { Global } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import React, { useState } from 'react';

import { Camera } from './components/Camera';
import { Controls } from './components/Controls';
import { MyTheme, Styled } from './theme';

const Container = Styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center"
}))

const App: React.FC = () => {
	const [zoom, setZoom] = useState(1)
	const [contrast, setContrast] = useState(1)
	const [brightness, setBrightness] = useState(1)

	return (
		<ThemeProvider theme={MyTheme}>
			<Global
				styles={{
					"html, body, #root, #root > div ": {
						backgroundColor: "#000",
						margin: 0,
						minHeight: "100%",
						display: "flex",
						flex: 1
					},
					"*": {
						boxSizing: "border-box"
					}
				}}
			/>

			<Container>
				<Camera
					zoom={zoom}
					contrast={contrast}
					brightness={brightness}
					video={{
						facingMode: { exact: "environment" },
						width: 3840,
						height: 2160
					}}
				/>
				<Controls
					zoom={zoom}
					setZoom={setZoom}
					contrast={contrast}
					setContrast={setContrast}
					brightness={brightness}
					setBrightness={setBrightness}
				/>
				<Camera
					zoom={zoom}
					contrast={contrast}
					brightness={brightness}
					video={{
						facingMode: { exact: "environment" },
						width: 3840,
						height: 2160
					}}
				/>
			</Container>
		</ThemeProvider>
	)
}

export default App
